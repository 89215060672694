import React, { useEffect } from "react";
import "assets/components/header-sito.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-one.json";
import Lottie from "lottie-web";
import { Grid } from "@mui/material";

const Header = (props) => {
  useEffect(() => {
    const container = document.getElementById("lottie-animation");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    const texts = [
      "In un click",
      "In cinque minuti",
      "Nella massima sicurezza",
      "Senza muoverti da casa",
    ];

    const textElement = document.querySelector(".subtitle-hero-denuncia");
    let currentIndex = 0;

    const interval = setInterval(() => {
      textElement.textContent = texts[currentIndex];
      currentIndex = (currentIndex + 1) % texts.length;
    }, 5000);

    return () => {
      animation.destroy();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Grid id="denuncia-online" container style={{ marginTop: "30px" }}>
        <Grid item xs={12} md={7}>
          <Grid item xs={12} className="cover-image">
            <div id="lottie-animation"></div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} className="padding-sectionText">
          <Grid item xs={12} className="container-section-1-sectionText">
            <h1 className="color1 title-hero-denuncia">La tua Denuncia</h1>
            <h2 className="color2 subtitle-hero-denuncia">in un click</h2>
            <p className="color1 paragraf-section-2 mb-0">
              Veloce, sicuro, online.
            </p>
            <p className="color1 paragraf-section-2">
              Presenta la tua denuncia da casa: registrati, compila e firma
              digitalmente. A tutto il resto ci pensiamo noi.
            </p>
            <a href="/register" className="btn btn-section-2">
              Inizia subito
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
