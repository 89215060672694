import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";
import { ThemeProvider, StyledEngineProvider } from "@mui/styles";
import MuiTheme from "./theme";
// Layout Blueprintss
import { BaseLayout, MinimalLayout } from "./layout-blueprints";
import Login from "./pages/Login";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import Home from "./pages/Home";
import Register from "./pages/Register";
import LostPassword from "./pages/LostPassword";
import Logout from "./pages/Logout";
import { history } from "./history";
import { useSelector } from "react-redux";
import ResetPassword from "pages/ResetPassword";
import Blog from "pages/Blog/Blog";
import VerificationMail from "pages/VerificationMail/verificationMail";
import PratichePage from "pages/Pratiche/Pratiche";
import ConsulenzaPage from "pages/Consulenza/ConsulenzaPage";
import ConsulenzaLandingPage from "pages/ConsulenzeLP/ConsulenzeLP";
import ForBusinessesLandingPage from "pages/LandingForBusinesses/ForBusinessesLandingPage";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const DenuncePage = lazy(() => import("./pages/Denunce/Denunce"));
const Builddata = lazy(() => import("./pages/Builddata"));
const Build = lazy(() => import("./pages/Build"));
const Preview = lazy(() => import("./pages/Preview"));
const Payment = lazy(() => import("./pages/Payment"));
const Download = lazy(() => import("./pages/Download"));
const PreviewPreventivo = lazy(() =>
  import("./pages/PreviewPreventivo/PreviewPreventivo")
);
const BlogDetail = lazy(() => import("./pages/Blog/BlogDetail"));
const NotFound = lazy(() => import("../src/pages/error404/error404"));
const InfoService = lazy(() => import("./pages/Detail/InfoService"));

const Routes = () => {
  const location = useLocation();
  const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
  const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

  const authenticated = useSelector((state) => state.report.customer);

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 100);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Loading...
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch history={history}>
          {partner ? (
            <Redirect exact from="/" to="/login" />
          ) : (
            <Route exact path="/" component={Home} />
          )}
          <Route exact path="/" component={Home} />
          <Route exact path="/b" component={Blog} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsConditions} />
          <Route exact path="/b/:slug" component={BlogDetail} />
          <Route exact path="/consulenza" component={ConsulenzaLandingPage} />
          <Route exact path="/aziende" component={ForBusinessesLandingPage} />

          <Route
            path={[
              "/dashboard",
              "/category/:category",
              "/insights",
              "/logout",
              "/builddata",
              "/build",
              "/preview",
              "/preview/preventivo/:id",
              "/payment",
              "/download",
            ]}
            render={() =>
              !authenticated ? (
                <Redirect to="/login" />
              ) : (
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}
                    >
                      <Route path="/dashboard" exact component={Dashboard} />
                      <Route
                        path="/dashboard/denunce"
                        component={DenuncePage}
                      />

                      <Route
                        path="/dashboard/pratiche"
                        component={PratichePage}
                      />
                      <Route
                        path="/dashboard/pratica/consulenza/:id"
                        component={ConsulenzaPage}
                      />
                      <Route path="/logout" component={Logout} />
                      <Route
                        path="/builddata/:reportId/:docBId?"
                        component={Builddata}
                      />
                      <Route
                        path="/build/:reportId/:docBId?"
                        component={Build}
                      />
                      <Route
                        path="/preview/:reportId"
                        component={PreviewPreventivo}
                      />
                      <Route
                        path="/preview/preventivo/:id"
                        component={Preview}
                      />
                      <Route path="/payment/:reportId" component={Payment} />
                      <Route path="/download/:reportId" component={Download} />
                      <Route
                        path="/dashboard/info-service"
                        component={InfoService}
                      />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              )
            }
          />

          <Route
            path={[
              "/login",
              "/register",
              "/lost-password",
              "/reset-password",
              "/PageRecoverOverlay",
              "/PageError404",
              "/PageError500",
              "/PageError505",
              "/verification",
            ]}
          >
            <BaseLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/lost-password" component={LostPassword} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/verification" component={VerificationMail} />
                </motion.div>
              </Switch>
            </BaseLayout>
          </Route>

          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
