import React, { Fragment, useEffect, useState } from "react";
import { Grid, TextField, Container, Typography, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../history";
import { actions } from "../../_model/report/actions";
import TextSimple from "components/TextSimple";
import ButtonPrimary from "components/ButtonPrimary";
import ButtonTertiary from "components/ButtonTertiary";
import TextHeaderSecondary from "components/TextHeaderSecondary";
import TextInfobox from "components/TextInfobox";

export default function LostPassword() {
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const errors = useSelector((state) => state.report.errors);

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setError(false);
    setSent(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = (event) => {
    // event.preventDefault();
    const { name, email, password, password_confirmation } = formState.values;
    dispatch(actions.requestPasswordReset(email));
    setSent(false);
    setTimeout(() => {
      setSent(true);
    }, 2000);
  };

  useEffect(() => {
    setError(errors !== null);
  }, [errors]);

  const customer = useSelector((state) => state.report.customer);

  useEffect(() => {
    if (customer) {
      history.push("/dashboard");
    }
  }, [customer]);

  return (
    <>
      <Container className="PageLogin" maxWidth="sm">
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          textAlign="center"
        >
          {sent && !error ? (
            <Grid item xs={12} style={{ marginTop: "50px" }}>
              <TextInfobox status="pending">
                <Typography
                  className="info-text-title"
                  style={{ textAlign: "start" }}
                >
                  Controlla la tua posta.
                </Typography>
                <Typography
                  className="info-text-subtitle"
                  style={{ textAlign: "start" }}
                >
                  Verifica la tua casella di posta e clicca sul link per
                  inserire la nuova password.
                </Typography>
              </TextInfobox>
            </Grid>
          ) : (
            <Fragment>
              {" "}
              <Grid item xs={12}>
                <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                  Inserici la tua email per recuperare la password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // helperText={errors && errors.email}
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  fullWidth
                  onChange={handleChange}
                  value={formState.values.email}
                  FormHelperTextProps={{
                    classes: {
                      root: "redHelperText",
                    },
                  }}
                />

                {errors && errors.email && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <div className="icon-error-message" />
                    <Typography className="text-message-error">
                      {errors && errors.email}
                    </Typography>
                  </div>
                )}

                {errors &&
                  ["email_social", "email_google", "email_facebook"].some(
                    (key) => errors[key]
                  ) &&
                  ["email_social", "email_google", "email_facebook"].map(
                    (key, index) =>
                      errors[key] ? (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <div className="icon-error-message" />
                          <Typography
                            className="text-message-error"
                            style={{ width: "95%" }}
                          >
                            {(() => {
                              const message = String(errors[key]);
                              return message
                                .split(/(\blogin\b)/)
                                .map((part, idx) =>
                                  part.toLowerCase() === "login" ? (
                                    <a
                                      key={idx}
                                      href="/login"
                                      style={{
                                        color: "#ffffff",
                                        textDecoration: "underline",
                                        textDecorationColor: "#ffffff",
                                      }}
                                    >
                                      {part}
                                    </a>
                                  ) : (
                                    part
                                  )
                                );
                            })()}
                          </Typography>
                        </div>
                      ) : null
                  )}
              </Grid>
              <Grid item xs={12}>
                <ButtonPrimary className="button-custom" onClick={handleSignIn}>
                  Recupera password
                </ButtonPrimary>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <Typography style={{ color: "#ffffff" }}>
              Ritorna alla pagina di{" "}
              <Link
                href="/login"
                style={{
                  color: "#ffffff",
                  textDecoration: "underline",
                  textDecorationColor: "#ffffff",
                }}
              >
                login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
