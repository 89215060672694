import React, { useEffect } from "react";
import "assets/components/theAdvantages.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-3.json";
import Lottie from "lottie-web";
import { Typography } from "@mui/material";

const TheAdvantages = (props) => {
  useEffect(() => {
    const container = document.getElementById("lottie-animation-2");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-2-smart");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  return (
    <>
      <div id="I-vantaggi" className="container-fluid p-0 container-section-4">
        <div className="col-12 col-md-6">
          <div id="lottie-animation-2" className="container-animation-2"></div>
        </div>

        <div className="col-12 col-lg-6 col-custom box2-section-4">
          <h2
            className="title-sectionThree-denuncia color1"
            style={{ fontWeight: 600 }}
          >
            I vantaggi
          </h2>
          <Typography
            className="color2 subtitle-sectionThree-denuncia"
            style={{ fontWeight: 600, width: "98%" }}
          >
            Perché presentare la tua denuncia su Denuncia Online?
          </Typography>
          <p className="color1 mt-3" style={{ width: "80%" }}>
            In Italia ci sono due modalità per presentare una denuncia: andare
            fisicamente dai Carabinieri o in Questura, perdendo molto tempo tra
            spostamenti in città e code d'attesa. Oppure tramite un avvocato
            che, dietro compenso, provvederà a trasmettere il documento alla
            Procura.
          </p>
          <p className="color1 mt-2 mb-0" style={{ width: "80%" }}>
            E poi c’è{" "}
            <span style={{ fontWeight: "bold" }}>Denuncia Online</span>, che ti
            permette di presentare la tua denuncia da remoto con un{" "}
            <span style={{ fontWeight: "bold" }}>sistema</span> tanto{" "}
            <span style={{ fontWeight: "bold" }}>veloce</span> quanto{" "}
            <span style={{ fontWeight: "bold" }}>sicuro</span>!
          </p>
          <p
            className="color1 m-0"
            style={{ width: "80%", fontWeight: "bold" }}
          >
            Perché presentare la tua denuncia sul nostro portale?
          </p>
          <div className="col-12 mt-3">
            <div
              id="lottie-animation-2-smart"
              className="container-animation-2-smart"
            ></div>
          </div>
          <div className="box3-section-4">
            <div className="row mt-4" style={{ width: "95%" }}>
              <div className="col-12 col-lg-6 col-custom margin-bottom-5">
                <div className="icon1"></div>
                <h4 className="color2 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Praticità
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Denuncia Online è un{" "}
                  <span style={{ fontWeight: "bold" }}>
                    servizio interamente online
                  </span>
                  : puoi inviare la tua denuncia da qualsiasi dispositivo,
                  ovunque tu sia, tenendo sotto controllo la pratica dalla tua
                  area riservata.
                </p>
              </div>
              <div className="col-12 col-lg-6 col-custom margin-bottom-5">
                <div className="icon2"></div>
                <h4 className="color2 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Facilità
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Usare Denuncia Online è{" "}
                  <span style={{ fontWeight: "bold" }}>semplicissimo</span>, ti
                  basteranno{" "}
                  <span style={{ fontWeight: "bold" }}>
                    tre passaggi (tutti guidati)
                  </span>{" "}
                  per poter presentare la tua denuncia e ottenere la ricevuta di
                  deposito.
                </p>
              </div>
            </div>
            <div className="row mt-2" style={{ width: "95%" }}>
              <div className="col-12 col-xl-6 col-custom margin-bottom-5">
                <div className="icon3"></div>
                <h4 className="color2 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Velocità
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Con il nostro servizio{" "}
                  <span style={{ fontWeight: "bold" }}>risparmi</span> tempo,
                  sia negli{" "}
                  <span style={{ fontWeight: "bold" }}>spostamenti</span> perché
                  puoi presentare la denuncia direttamente da casa, sia perché
                  abbiamo{" "}
                  <span style={{ fontWeight: "bold" }}>
                    semplificato la procedura
                  </span>
                  : l’intero processo dura pochi minuti!
                </p>
              </div>
              <div className="col-12 col-xl-6 col-custom margin-bottom-5">
                <div style={{ display: "flex" }}>
                  <div className="icon4"></div>
                  <div className="ref"></div>
                </div>
                <h4 className="color2 fs-4 mt-2" style={{ fontWeight: "bold" }}>
                  Sicurezza e trasparenza
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Garantiamo{" "}
                  <span style={{ fontWeight: "bold" }}>
                    totale trasparenza e pieno valore legale
                  </span>{" "}
                  della pratica. Siamo nati con il supporto di Lexia, studio
                  legale con grande esperienza e professionalità. La tua
                  denuncia rispetterà tutti i requisiti del Ministero della
                  Giustizia. <br />
                  {"->"}{" "}
                  <a
                    target="_blank"
                    href="https://www.giustizia.it/giustizia/it/mg_3_2_16.page"
                    className="link-section-4"
                  >
                    verifica qui
                  </a>
                </p>
              </div>
            </div>
          </div>
          <a className="btn btn-section-4 mt-3" href="/register">
            Inizia subito
          </a>
        </div>
      </div>
    </>
  );
};

export default TheAdvantages;
