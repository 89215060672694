import React, { useEffect } from "react";
import "assets/components/header-sito-consulenza.scss";
import "assets/components/header-sito.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-hero-consulenza.json";
import Lottie from "lottie-web";
import { Grid } from "@mui/material";

const HeaderConsulenza = (props) => {
  useEffect(() => {
    const container = document.getElementById("lottie-animation-consulenza");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    const texts = ["professionale", "in videocall"];

    const textElement = document.querySelector(".subtitle-hero-consulenza");
    let currentIndex = 0;

    const interval = setInterval(() => {
      textElement.textContent = texts[currentIndex];
      currentIndex = (currentIndex + 1) % texts.length;
    }, 5000);

    return () => {
      animation.destroy();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Grid id="denuncia-online" container style={{ marginTop: "30px" }}>
        <Grid
          item
          xs={12}
          md={6}
          className="padding-sectionText"
          sx={{ order: { xs: 2, md: 1 } }}
        >
          <Grid item xs={12} className="container-section-1-sectionText">
            <div className="margin-left-text">
              <h1 className="color1 title-hero-consulenza">
                Consulenza dedicata{" "}
              </h1>
              <h2 className="color4 subtitle-hero-consulenza">con avvocati</h2>

              <p className="color1 mt-3 paragraf-section-2 mb-0">
                Risolvi online questioni legali di natura penale.
              </p>
              <p className="color1 paragraf-section-2">
                Raccontaci il tuo caso, un professionista chiarirà ogni tuo
                dubbio.
              </p>
              <a href="/register" className="btn btn-section-consulenza">
                Richiedi subito
              </a>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          sx={{ order: { xs: 1, md: 2 } }}
        >
          <Grid item xs={12} className="cover-image-consulenza">
            <div id="lottie-animation-consulenza"></div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderConsulenza;
