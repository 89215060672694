import React, { useEffect } from "react";
import "assets/components/ForBusinesses/header.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-hero-aziende.json";
import Lottie from "lottie-web";
import { Grid } from "@mui/material";

const Header = ({ openCalendlyPopup }) => {
  useEffect(() => {
    const container = document.getElementById(
      "lottie-animation-header-aziende"
    );
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6.5}>
          <Grid item xs={12} className="cover-image-header-aziende">
            <div id="lottie-animation-header-aziende"></div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          className="padding-sectionText-header-aziende"
        >
          <Grid item xs={12} className="container-header-aziende-sectionText">
            <h1 className="color1 title-hero-aziende">
              Il sistema automatizzato per le denunce dei tuoi clienti
            </h1>

            <p className="color1 paragraf-header-aziende mb-0">
              Soluzioni integrate e personalizzate.
            </p>

            <p className="color1 paragraf-header-aziende mb-0">
              Digitalizziamo la gestione di denunce e documenti per la tua
              azienda.
            </p>

            <a onClick={openCalendlyPopup} className="btn btn-header-aziende">
              Richiedi una demo
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
