import React, { useEffect } from "react";
import "assets/components/cardProduct.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const CardProductConsulenza = (props) => {
  return (
    <>
      <Grid container className="container-section-5">
        <Grid item xs={10} className="overlay-consulenza text-center">
          <h1 className="mt-5 title-sectionFour-denuncia">
            Una tariffa accessibile a tutti!
          </h1>
          <p className="m-0 mt-2 subtitle-card">
            Abbiamo una missione: rendere la consulenza penale uno strumento
            facilmente raggiungibile.
          </p>
          <p className="subtitle-card">
            I{" "}
            <span style={{ fontWeight: "bold" }}>
              primi 30 minuti di consulenza sono sempre inclusi
            </span>{" "}
            nella nostra tariffa standard!
          </p>
          <p className="mt-3">
            <span className="price-card">&euro;</span>
            <span className="number-price-card">69</span>
            <span className="number2-price-card">,90</span>
          </p>

          <a href="/register" className="btn btn-section-5-consulenza mt-4">
            Richiedi subito
          </a>
          <p className="mt-4 text-info">
            Hai ancora dubbi?{" "}
            <a href="#Faq" style={{ fontWeight: "bold", color: "#ffffff" }}>
              Consulta le nostre FAQ
            </a>
          </p>
        </Grid>
        <div
          className="background-under-consulenza col-12"
          style={{ flexShrink: 0 }}
        ></div>
      </Grid>
    </>
  );
};

export default CardProductConsulenza;
