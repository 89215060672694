import React, { useEffect } from "react";
import "assets/components/cardProduct.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const CardProduct = (props) => {
  return (
    <>
      <Grid container className="container-section-5">
        <Grid item xs={10} className="overlay text-center">
          <h1 className="mt-5 title-sectionFour-denuncia">
            Un prezzo chiaro, senza sorprese!
          </h1>
          <p className="m-0 mt-2 subtitle-card">
            Grazie a Denuncia Online risparmierai tempo ed eviterai di prendere
            permessi o ferie a lavoro.
          </p>
          <p className="subtitle-card">
            Tutto questo a un{" "}
            <span style={{ fontWeight: "bold" }}>
              prezzo piccolo, chiaro e senza brutte sorprese
            </span>
            !
          </p>
          <p className="mt-3">
            <span className="price-card">&euro;</span>
            <span className="number-price-card">19</span>
            <span className="number2-price-card">,90</span>
          </p>

          <a href="/register" className="btn btn-section-5 mt-4">
            Inizia subito
          </a>
          <p className="mt-4 text-info">
            Hai ancora dubbi?{" "}
            <a href="#Faq" style={{ fontWeight: "bold", color: "#ffffff" }}>
              Consulta le nostre FAQ
            </a>
          </p>
        </Grid>
        <div className="background-gray col-12" style={{ flexShrink: 0 }}></div>
      </Grid>
    </>
  );
};

export default CardProduct;
