import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Grid, Typography } from "@mui/material";
import "assets/components/TalkAboutUs.scss";

const TalkAboutUs = ({ aziende }) => {
  const logos = [
    "laRepublica-logo",
    "wired-logo",
    "ilGiornale-logo",
    "ilGiorno-logo",
    "leggo-logo",
    "qnEconomia-logo",
    "mondoFinanza-logo",
    "donnaModerna-logo",
    "carlino-logo",
    "laNazione-logo",
    "borsaItaliana-logo",
  ];

  return (
    <Grid
      container
      className={
        aziende ? "container-section-talkAboutUs-aziende" : "container-section"
      }
      // className="container-section"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid xs={10} sm={11} lg={10}>
        <Typography className="text-section color1">Parlano di noi</Typography>
      </Grid>

      <Grid
        item
        xs={10}
        sm={11}
        lg={10}
        style={{ height: "120px", overflow: "hidden" }}
      >
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={2000}
          loop
          breakpoints={{
            0: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 20 },
            // 600: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 0 },
            900: { slidesPerView: 5, slidesPerGroup: 1, spaceBetween: 0 },
          }}
          style={{ width: "100%", height: "100%" }}
        >
          {logos.map((logo, index) => (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid
                item={1}
                className={logo}
                style={{ width: "130px", height: "80px" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default TalkAboutUs;
