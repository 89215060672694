import React, { useEffect } from "react";
import "assets/components/ForBusinesses/sectionThree.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import animationData from "assets/img/animation-sectionThree-aziende.json";
import Lottie from "lottie-web";
import { Typography } from "@mui/material";

const SectionThree = ({ openCalendlyPopup }) => {
  useEffect(() => {
    const container = document.getElementById(
      "lottie-animation-sectionThree-aziende"
    );
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-2-smart");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);
  return (
    <>
      <div className="container-fluid p-0 container-sectionThree-aziende">
        <div className="col-12 col-md-6">
          <div
            id="lottie-animation-sectionThree-aziende"
            className="container-animation-sectionThree-aziende"
          ></div>
        </div>

        <div className="col-12 col-lg-6 col-custom-sectionThree-aziende box-sectionThree-aziende">
          <div className="col-12 mt-3">
            <div
              id="lottie-animation-2-smart"
              className="container-animation-smart-sectionThree-aziende"
            ></div>
          </div>
          <h1
            className="title-sectionThree-aziende color1"
            style={{ fontWeight: 600 }}
          >
            A chi è rivolto DenunciaOnline B2B?
          </h1>
          <Typography
            className="color-purple subtitle-sectionThree-aziende"
            style={{ fontWeight: 600, width: "98%" }}
          >
            Le nostre soluzioni sono ideali per:
          </Typography>

          <div className="box3-sectionThree-aziende">
            <div className="row mt-4" style={{ width: "95%" }}>
              <div className="col-12 col-lg-6 col-custom-sectionThree-aziende margin-bottom-5">
                <div className="icon1-aziende"></div>
                <h4
                  className="color-purple fs-4 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  Banche
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Per digitalizzare la raccolta delle denunce per la{" "}
                  <span style={{ fontWeight: "bold" }}>
                    riattivazione di carte
                  </span>{" "}
                  o altri titoli di pagamento.
                </p>
              </div>

              <div className="col-12 col-lg-6 col-custom-sectionThree-aziende margin-bottom-5">
                <div className="icon2-aziende"></div>
                <h4
                  className="color-purple fs-4 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  Assicurazioni
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Per semplificare la{" "}
                  <span style={{ fontWeight: "bold" }}>
                    gestione dei sinistri
                  </span>{" "}
                  dei contraenti e l’attivazione dei rimborsi quando dovuti.
                </p>
              </div>
            </div>

            <div className="row mt-2" style={{ width: "95%" }}>
              <div className="col-12 col-xl-6 col-custom-sectionThree-aziende margin-bottom-5">
                <div className="icon3-aziende"></div>
                <h4
                  className="color-purple fs-4 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  E-commerce e portali
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Per gestire le segnalazioni di{" "}
                  <span style={{ fontWeight: "bold" }}>truffe</span>, addebiti
                  fraudolenti o reclami con rilevanza penale su prodotti e
                  servizi.
                </p>
              </div>

              <div className="col-12 col-xl-6 col-custom-sectionThree-aziende margin-bottom-5">
                <div style={{ display: "flex" }}>
                  <div className="icon4-aziende"></div>
                </div>
                <h4
                  className="color-purple fs-4 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  Servizi per i cittadini
                </h4>
                <p
                  className="color1"
                  style={{ fontSize: "16px", fontWeight: 500 }}
                >
                  Per facilitare la raccolta di{" "}
                  <span style={{ fontWeight: "bold" }}>segnalazioni</span>,
                  garantendo la tracciabilità del flusso (es. furti sui mezzi di
                  trasporto).
                </p>
              </div>
            </div>
          </div>
          <a
            className="btn btn-sectionThree-aziende mt-3"
            onClick={openCalendlyPopup}
          >
            Richiedi una demo
          </a>
        </div>
      </div>
    </>
  );
};

export default SectionThree;
