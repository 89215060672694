import React, { useEffect } from "react";
import "assets/components/ForBusinesses/sectionFour.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const SectionFour = ({ openCalendlyPopup }) => {
  return (
    <>
      <Grid container className="container-sectionFour-aziende">
        <Grid item xs={10} className="overlay-sectionFour-aziende text-center">
          <h1 className="mt-5 title-card-sectionFour-aziende">
            Troviamo insieme la soluzione per la tua azienda
          </h1>
          <p className="m-0 mt-2 subtitle-card-sectionFour-aziende">
            Vuoi scoprire come DenunciaOnline può integrarsi nei tuoi processi
            aziendali?
          </p>
          <p className="subtitle-card-sectionFour-aziende">
            Un nostro consulente ti contatterà per mostrarti il nostro servizio
            e proporti una{" "}
            <span style={{ fontWeight: "bold" }}>soluzione su misura</span>.
          </p>

          <a
            onClick={openCalendlyPopup}
            className="btn btn-sectionFour-aziende mt-4 mb-4"
          >
            Richiedi una demo
          </a>
        </Grid>
        <div
          className="background-purple-light col-12"
          style={{ flexShrink: 0 }}
        ></div>
      </Grid>
    </>
  );
};

export default SectionFour;
