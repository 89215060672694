import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid, Typography } from "@mui/material";
import {
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
} from "@mui/icons-material";
import ReactHtmlParser from "react-html-parser";
import Lottie from "lottie-web";
import animationData from "assets/img/animation-blog.json";
import StarIcon from "@mui/icons-material/Star";
import "assets/components/BlogDetail.scss";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BlogDetailArticle = (props) => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState({});
  const [prevArticle, setPrevArticle] = useState(null);
  const [nextArticle, setNextArticle] = useState(null);

  const { slug } = useParams();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + "/blog"
        );
        if (!response.ok) throw new Error("Errore nella richiesta API");

        const data = await response.json();
        setArticles(data.data.reverse());
      } catch (error) {
        console.error(
          "Errore durante il recupero degli articoli:",
          error.message
        );
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const fetchArticleBySlug = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_ENDPOINT + `/blog/article/slug/${slug}`
        );

        if (!response.ok) throw new Error("Errore nella richiesta API");

        const data = await response.json();
        setSelectedArticle(data.data);
      } catch (error) {
        console.error(
          "Errore durante il recupero dell'articolo:",
          error.message
        );
      }
    };

    fetchArticleBySlug();
  }, [slug]);

  useEffect(() => {
    if (articles.length && selectedArticle.id) {
      const sortedArticles = [...articles].sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      const currentIndex = sortedArticles.findIndex(
        (art) => art.id === selectedArticle.id
      );

      setPrevArticle(
        currentIndex > 0 ? sortedArticles[currentIndex - 1] : null
      );
      setNextArticle(
        currentIndex < sortedArticles.length - 1
          ? sortedArticles[currentIndex + 1]
          : null
      );
    }
  }, [articles, selectedArticle]);

  useEffect(() => {
    const container = document.getElementById("lottie-animation-three");
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  const slugAltImg = selectedArticle?.slug || "";
  const formattedAlt = slugAltImg.replace(/-/g, " ");

  useEffect(() => {
    if (selectedArticle && selectedArticle.meta_description) {
      document
        .querySelector("meta[name='description']")
        .setAttribute("content", selectedArticle.meta_description);
    }
  }, [selectedArticle]);

  const capitalizeTitle = (title) => {
    return title
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {selectedArticle?.title
              ? `${capitalizeTitle(selectedArticle.title)} - DenunciaOnline`
              : "DenunciaOnline"}
          </title>
          <meta
            name="description"
            content={
              selectedArticle?.meta_description ||
              "DenunciaOnline - Ultime notizie"
            }
          />
        </Helmet>
      </HelmetProvider>

      {selectedArticle ? (
        <Grid
          container
          className="Blog"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item md={8} xs={10} style={{ textAlign: "center" }}>
            <Typography className="textDescription title">
              {selectedArticle.title}
            </Typography>

            <Grid
              item
              xs={12}
              style={{ width: "100%", marginTop: "50px", marginBottom: "50px" }}
            >
              <div className="containerImage">
                <img
                  className="containerImageBlog"
                  src={
                    selectedArticle.featured_image
                      ? `data:image/png;base64,${selectedArticle.featured_image}`
                      : ""
                  }
                  alt={formattedAlt}
                />
              </div>
            </Grid>

            <Typography
              style={{
                marginTop: "50px",
                fontSize: "18px",
                textAlign: "start",
                color: "#20305b",
                fontFamily: "Quicksand, sans-serif !important",
              }}
              className="textDescription"
            >
              {ReactHtmlParser(selectedArticle.content)}
            </Typography>

            {/* gestione button  */}

            {selectedArticle.content ? (
              <Grid xs={12} className="container-button-article-details">
                <Grid
                  item
                  xs={2}
                  sm={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="container-previous-article">
                    <a
                      href={prevArticle ? `/b/${prevArticle.slug}` : undefined}
                      aria-disabled={!prevArticle}
                      className={`color1 ${
                        !prevArticle ? "disabled-link" : ""
                      }`}
                    >
                      <KeyboardDoubleArrowLeftOutlined
                        style={{ fontSize: "12px", marginRight: "4px" }}
                      />
                      Articolo precedente
                    </a>
                  </div>

                  <div className="container-previous-article-icon">
                    <a
                      href={prevArticle ? `/b/${prevArticle.slug}` : undefined}
                      aria-disabled={!prevArticle}
                      className={`color1 ${
                        !prevArticle ? "disabled-link" : ""
                      }`}
                    >
                      <KeyboardDoubleArrowLeftOutlined className="icon-previous" />
                    </a>
                  </div>
                </Grid>

                <Grid item xs={8} sm={4}>
                  <a href={`/b`} size="small" className="color1">
                    Tutti gli articoli
                  </a>
                </Grid>

                <Grid
                  item
                  xs={2}
                  sm={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="container-next-article">
                    <a
                      href={nextArticle ? `/b/${nextArticle.slug}` : undefined}
                      aria-disabled={!nextArticle}
                      className={`color1 ${
                        !nextArticle ? "disabled-link" : ""
                      }`}
                    >
                      Articolo successivo
                      <KeyboardDoubleArrowRightOutlined
                        style={{ fontSize: "12px", marginLeft: "4px" }}
                      />
                    </a>
                  </div>

                  <div className="container-next-article-icon">
                    <a
                      href={nextArticle ? `/b/${nextArticle.slug}` : undefined}
                      aria-disabled={!nextArticle}
                      className={`color1 ${
                        !nextArticle ? "disabled-link" : ""
                      }`}
                    >
                      <KeyboardDoubleArrowRightOutlined className="icon-next" />
                    </a>
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          {/* CTA Section */}
          <Grid container style={{ width: "100%", marginTop: "30px" }}>
            <Grid
              item
              xs={12}
              lg={11}
              className="bg-section"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={10} sm={3} style={{ display: "flex" }}>
                <div
                  id="lottie-animation-three"
                  className="container-animation-blog"
                ></div>
              </Grid>
              <Grid item xs={10} sm={8} style={{ display: "flex" }}>
                <div className="container-preFooter">
                  <Typography className="title-preFooter">
                    Vuoi fare una denuncia online ora?
                  </Typography>
                  <Typography className="text-preFooter">
                    DenunciaOnline è il primo servizio in Italia che ti permette
                    di presentare una denuncia ovunque ti trovi, direttamente
                    online.
                  </Typography>

                  <Grid item xs={10} className="container-icon-preFooter">
                    <Grid item xs={8} sm={3} className="containerSmallIcon">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{ marginLeft: "5px" }}
                        className="text-icon-preFooter"
                      >
                        Bastano 2 min
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={4} className="containerSmallIcon-2">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{ marginLeft: "5px" }}
                        className="text-icon-preFooter"
                      >
                        Da PC o smartphone
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={4} className="containerSmallIcon-3">
                      <StarIcon style={{ color: "#58979d" }} />
                      <Typography
                        style={{ marginLeft: "5px" }}
                        className="text-icon-preFooter"
                      >
                        Modulo personalizzato
                      </Typography>
                    </Grid>
                  </Grid>

                  <div className="btn-preFooter">
                    <a href="/register" className="btn btn-section-5 mt-4">
                      Inizia subito
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default BlogDetailArticle;
