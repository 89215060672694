import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Container,
  Typography,
  Link,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../history";
import { actions } from "../../_model/report/actions";
import TextSimple from "components/TextSimple";
import FacebookLogin from "components/FacebookLogin";
import GoogleLogin from "components/GoogleLogin";
import Divider from "components/Divider";
import ButtonPrimary from "components/ButtonPrimary";
import ButtonTertiary from "components/ButtonTertiary";
import { Helmet } from "react-helmet";
import GoogleLoginCustom from "components/GoogleLoginCustom";

export default function Login() {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const partnerEnv = process.env.REACT_APP_PARTNERSHIP;
  const partner = partnerEnv ? !!JSON.parse(partnerEnv) : false;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setError(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = (event) => {
    // event.preventDefault();
    const { email, password } = formState.values;
    dispatch(actions.login(email, email, password, null));
  };

  // const fbResponse = event => {
  //   const { email, name, accessToken } = event;
  //   dispatch(actions.register(email, name, accessToken, accessToken));
  // };

  const customer = useSelector((state) => state.report.customer);

  const errors = useSelector((state) => state.report.errors);

  useEffect(() => {
    if (customer) {
      history.push("/dashboard");
    }
  }, [customer]);

  useEffect(() => {
    dispatch(actions.loadReports());
    document.body.setAttribute("data-root", window.location.pathname);
    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>DenunciaOnline.eu</title>
        <meta
          name="description"
          content="Il primo e unico portale italiano per presentare una denuncia completamente online."
        />
      </Helmet>
      <Container className="PageLogin" maxWidth="sm">
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          textAlign="center"
        >
          {partner === true ? (
            <>
              <Grid item xs={12} style={{ marginTop: "50px" }}>
                <Typography style={{ color: "#ffffff", fontWeight: 400 }}>
                  Entra con le tue credenziali
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                className="containerButton"
                style={{ marginTop: "20px" }}
              >
                <Grid className="containerGoogle" item xs={12} sm={5}>
                  <GoogleLoginCustom />
                </Grid>
                <Grid className="containerFacebook" item xs={12} sm={5}>
                  <FacebookLogin />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontWeight: 400,
                    marginBottom: "10px",
                    marginTop: "-20px",
                  }}
                >
                  Oppure
                </Typography>
                <Typography style={{ color: "#ffffff", fontWeight: 400 }}>
                  Entra con le tue credenziali
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {error && <div>Utente non riconosciuto!</div>}

            <TextField
              // helperText={
              //   (errors && errors.email) ||
              //   (errors && errors.email_not_verified)
              // }
              id="email"
              variant="outlined"
              name="email"
              fullWidth
              onChange={handleChange}
              value={formState.values.email}
              placeholder="Email"
              FormHelperTextProps={{
                classes: {
                  root: "redHelperText",
                },
              }}
            />
            {errors && (errors.email || errors.email_not_verified) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <div className="icon-error-message" />
                <Typography className="text-message-error">
                  {errors.email || errors.email_not_verified}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="password"
              fullWidth
              name="password"
              value={formState.values.password}
              onChange={handleChange}
              placeholder="Password"
              FormHelperTextProps={{
                classes: {
                  root: "redHelperText",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {/* <ButtonTertiary  href="/lost-password"><strong>Clicca qui per recuperare la password</strong></ButtonTertiary> */}
            <Link
              style={{
                color: "#ffffff",
                textDecoration: "underline",
                textDecorationColor: "#ffffff",
              }}
              href="/lost-password"
            >
              Password dimenticata?
            </Link>
          </Grid>

          <Grid item xs={12}>
            <ButtonPrimary className="button-custom" onClick={handleSignIn}>
              Login
            </ButtonPrimary>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "50px" }}>
            <Typography
              style={{
                color: "#ffffff",
                paddingBottom: "0px",
              }}
            >
              Non hai ancora un account?&nbsp;{" "}
              <Link
                href="/register"
                style={{
                  color: "#ffffff",
                  textDecoration: "underline",
                  textDecorationColor: "#ffffff",
                }}
              >
                Registrati
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
