import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { actions } from "_model/report";
import ButtonPrimary from "components/ButtonPrimary";
import ButtonSecondary from "components/ButtonSecondary";
import CalendlyReportCard from "components/CalendlyReportCard";
import TextInfobox from "components/TextInfobox";
import logoTrustpilot from "assets/img/Trustpilot.png";

export default function ConsulenzaPage() {
  const dispatch = useDispatch();
  const [idConsulenza, setIdConsulenza] = useState(null);
  const [memo, setMemo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [monitorEventCalendly, setMonitorEventCalendly] = useState(false);

  const memos = useSelector((state) => state.report.customer.memos);

  useEffect(() => {
    dispatch(actions.loadReports());
  }, [dispatch]);

  useEffect(() => {
    const url = window.location.href;
    const id = url.split("/").pop();
    setIdConsulenza(Number(id));
  }, [memos]);

  useEffect(() => {
    if (idConsulenza && Array.isArray(memos) && memos.length > 0) {
      const selectedMemo = memos.find((memo) => memo.id === idConsulenza);
      if (selectedMemo) {
        setMemo(selectedMemo);
        setLoading(false);
      } else {
        setMemo(null);
        setLoading(false);
      }
    }
  }, [idConsulenza, memos]);

  const handleButtonClick = () => {
    setMonitorEventCalendly(true);

    if (memo) {
      window.Calendly.initPopupWidget({
        url: `https://calendly.com/reschedulings/${memo.invitee_uuid}`,
      });
    }
    return false;
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (
        (event.target &&
          event.target.classList.contains("calendly-popup-close")) ||
        event
      ) {
        setTimeout(() => {
          dispatch(actions.loadReports());
          setMonitorEventCalendly(false);
        }, 500);
      }
    };

    if (monitorEventCalendly) {
      window.addEventListener("click", handleWindowClick);
    }

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [monitorEventCalendly]);

  return (
    <>
      {memo && !loading && (
        <Grid
          container
          marginTop={2}
          rowSpacing={3}
          justifyContent="center"
          textAlign="center"
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              href={"/dashboard/pratiche"}
              style={{
                color: "#fff",
                marginTop: "5px",
              }}
            >
              <div className="icon-back-dashoard" />
            </a>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <Typography variant="h5" className="ProductNumber">
              Pratica di consulenza <span>#{memo.id}</span>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <CalendlyReportCard memo={memo} />
          </Grid> */}

          <Grid
            container
            marginTop={2}
            marginBottom={4}
            rowSpacing={3}
            justifyContent="center"
            textAlign="left"
            style={{ marginBottom: "80px" }}
          >
            <Grid item xs={12}>
              {memo && memo.status === "In corso" ? (
                <TextInfobox status="pending">
                  <Typography className="info-text-title pratica_compilata">
                    Info
                  </Typography>
                  <Typography className="info-text-subtitle">
                    La consulenza ha una durata indicativa di 30 minuti. Prepara
                    il tuo quesito in modo che sia immediatamente comprensibile
                    al professionista. Il bottone "Vai alla videocall" sarà
                    attivo il giorno e l'ora in cui hai prenotato
                    l'appuntamento. Se hai bisogno di modificare il tuo
                    appuntamento, clicca sul bottone apposito.
                  </Typography>
                  <Typography className="info-text-subtitle">
                    Per qualunque dubbio contattaci in chat o scrivici
                    all'indirizzo{" "}
                    <a
                      href="mailto:info@denunciaonline.eu"
                      style={{ fontWeight: "bold", color: "#20305b" }}
                    >
                      info@denunciaonline.eu
                    </a>
                    . Il nostro servizio clienti è a tua completa disposizione.
                  </Typography>
                </TextInfobox>
              ) : memo && memo.status === "Archiviata" ? (
                <TextInfobox status="error">
                  <Typography
                    className="info-text-title"
                    style={{ marginTop: "5px" }}
                  >
                    Consulenza archiviata.
                  </Typography>
                  <Typography
                    className="info-text-subtitle"
                    style={{ marginBottom: "10px" }}
                  >
                    La consulenza è stata archiviata su tua specifica richiesta
                    e non sarà erogata.
                  </Typography>
                </TextInfobox>
              ) : memo && memo.status === "Completata" ? (
                <TextInfobox status="complete">
                  <Typography
                    className="info-text-title"
                    style={{ marginTop: "5px" }}
                  >
                    Consulenza completata.
                  </Typography>
                  <Typography
                    className="info-text-subtitle"
                    style={{ marginBottom: "10px" }}
                  >
                    La consulenza è stata erogata. Se avessi bisogno di una
                    nuova consulenza puoi richiederla facilmente dalla tua
                    dashboard. <br />
                    <span className="text-bold">
                      Grazie per aver scelto il nostro servizio.
                    </span>
                  </Typography>
                </TextInfobox>
              ) : null}
            </Grid>

            {(memo && memo.status === "Archiviata") ||
            (memo && memo.status === "Completata") ? null : (
              <>
                <Grid item xs={12}>
                  <ButtonPrimary consulenza={true} className="button-custom">
                    <a
                      target="_black"
                      style={{
                        width: "100%",
                        height: "100%",
                        textDecoration: "none",
                        color: "#fff",
                        padding: "16px",
                      }}
                      href={`${memo.invitee_event_url}`}
                    >
                      Vai alla videocall
                    </a>
                  </ButtonPrimary>
                </Grid>
                <Grid item xs={12}>
                  <ButtonSecondary
                    consulenza={true}
                    className="button-custom-secondary"
                  >
                    <a
                      onClick={handleButtonClick}
                      target="_black"
                      style={{
                        width: "100%",
                        height: "100%",
                        textDecoration: "none",
                        color: "#fff",
                        padding: "15px",
                      }}
                    >
                      Modifica data e ora videocall
                    </a>
                  </ButtonSecondary>
                </Grid>
              </>
            )}

            {/* <Grid
              item
              xs={12}
              style={{
                marginTop:
                  (memo && memo.status === "Archiviata") ||
                  (memo && memo.status === "Completata")
                    ? "20px"
                    : "0px",
              }}
            >
              <ButtonSecondary
                className="button-custom-secondary"
                href="/dashboard/pratiche"
              >
                Torna alla dashboard
              </ButtonSecondary>
            </Grid> */}

            {memo && memo.status === "Completata" ? (
              <>
                <Grid
                  container
                  marginBottom={4}
                  rowSpacing={3}
                  justifyContent="center"
                  textAlign="center"
                  sx={{ marginTop: "0px" }}
                >
                  <Grid item xs={12} sx={{ marginTop: "15px" }}>
                    <Typography sx={{ color: "#fff", fontSize: "1.1rem" }}>
                      La tua opinione per noi è molto importante.
                    </Typography>
                    <Typography sx={{ color: "#fff", fontSize: "1.1rem" }}>
                      Aiutaci a crescere per aiutare sempre più cittadini.
                    </Typography>
                  </Grid>
                </Grid>

                <div className="trustpilot">
                  <div
                    class="trustpilot-widget"
                    data-locale="it-IT"
                    data-template-id="56278e9abfbbba0bdcd568bc"
                    data-businessunit-id="648751c616b6170574592cfe"
                    data-style-height="52px"
                    data-style-width="100%"
                    className="trustpilot"
                  >
                    <a
                      // href="https://it.trustpilot.com/review/denunciaonline.eu"
                      href=" https://it.trustpilot.com/evaluate/denunciaonline.eu?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
                      target="_blank"
                      rel="noopener"
                    >
                      Lascia una recensione
                    </a>
                    <img src={logoTrustpilot} width="120" />
                  </div>
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      )}
    </>
  );
}
