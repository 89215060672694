import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Navbar from "components/ComponentHtml/Navbar";
import Header from "components/ComponentHtml/ForBusinessesComponent/Header";
import SectionTwo from "components/ComponentHtml/ForBusinessesComponent/SectionTwo";
import SectionThree from "components/ComponentHtml/ForBusinessesComponent/SectionThree";
import SectionFour from "components/ComponentHtml/ForBusinessesComponent/SectionFour";
import FooterOnePage from "components/ComponentHtml/FooterOnePage";
import SectionDas from "components/ComponentHtml/ForBusinessesComponent/SectionDas";
import InfoHomeAziende from "components/ComponentHtml/ForBusinessesComponent/InfoHomeAziende";
import TalkAboutUs from "components/ComponentHtml/TalkAboutUs";

export default function ForBusinessesLandingPage() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://calendly.com/assets/external/widget.js";
    script.type = "text/javascript";
    script.async = true;
    document.head.appendChild(script);

    const handleCalendlyEvent = (e) => {
      // Gestione degli eventi di Calendly qui (se necessario)
    };

    script.onload = () => {
      setTimeout(() => {
        if (
          window.Calendly &&
          typeof window.Calendly.initInlineWidgets === "function"
        ) {
          window.Calendly.initInlineWidgets();
        }
      }, 1000);
      window.addEventListener("message", handleCalendlyEvent);
    };

    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, []);

  const openCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.showPopupWidget(
        "https://calendly.com/denunciaonline/demo-aziende"
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={{ display: "none" }} />
      ) : (
        <>
          <Navbar />
          <Header openCalendlyPopup={openCalendlyPopup} />
          <TalkAboutUs aziende={true} />
          <SectionTwo openCalendlyPopup={openCalendlyPopup} />
          <SectionThree openCalendlyPopup={openCalendlyPopup} />
          <SectionFour openCalendlyPopup={openCalendlyPopup} />
          <SectionDas openCalendlyPopup={openCalendlyPopup} />
          <InfoHomeAziende openCalendlyPopup={openCalendlyPopup} />
          <FooterOnePage />
        </>
      )}
    </>
  );
}
