import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "assets/components/ConsulenzaHome.scss";
import animationData from "assets/img/animation-consulenza-home.json";
import Lottie from "lottie-web";

const ConsulenzaHome = () => {
  useEffect(() => {
    const container = document.getElementById(
      "lottie-animation-consulenza-home"
    );
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <Grid container className="container-consulenza-minorSection">
      <Grid
        sx={{ order: { xs: 2, md: 1 } }}
        item
        xs={10}
        md={5}
        className="container-text-consulenza"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            className="title-sectionConsulenza-denuncia color1"
            style={{ fontWeight: 600 }}
          >
            Hai bisogno di una consulenza?
          </Typography>
          <p className="color1 mt-3">
            Sono tanti i dubbi che possono nascere quando si ha a che fare con
            un reato o con il diritto penale in genere. Non sai se presentare o
            meno una denuncia? Hai bisogno di un consiglio professionale su un
            fatto che ti è accaduto? Per questo e tanto altro ancora puoi
            contare su di noi!
          </p>
          <a
            href="/consulenza"
            className="color1 link-margin"
            style={{ fontWeight: "bold" }}
          >
            Scopri di più
          </a>
        </div>
      </Grid>

      <Grid
        sx={{ order: { xs: 1, md: 2 } }}
        item
        xs={12}
        md={6}
        className="cover-image-consulenza-home"
      >
        <div id="lottie-animation-consulenza-home"></div>
      </Grid>
    </Grid>
  );
};

export default ConsulenzaHome;
