import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import "assets/components/ForBusinesses/sectionDas.scss";
import animationData from "assets/img/animation-consulenza-home.json";
import Lottie from "lottie-web";

const SectionDas = ({ openCalendlyPopup }) => {
  useEffect(() => {
    const container = document.getElementById(
      "lottie-animation-consulenza-home"
    );
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: "svg",
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <Grid
      container
      className="container-sectionDas-aziende"
      sx={{
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid
        sx={{ order: { xs: 2, md: 1 } }}
        item
        xs={10}
        md={5}
        className="container-text-sectionDas-aziende"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            className="title-sectionDas-aziende color1"
            style={{ fontWeight: 600 }}
          >
            Focus: Denuncia X DAS
          </Typography>
          <Typography
            className="subtitle-sectionDas-aziende color-purple"
            // style={{ marginTop: "40px" }}
          >
            DAS e tutela legale: innovazione e accessibilità.
          </Typography>
          <Typography className="color1 mt-3 text-sectionDas-aziende">
            <span style={{ fontWeight: "bold" }}>
              DAS, leader nella tutela legale
            </span>{" "}
            e parte di Generali Assicurazioni, ha scelto DenunciaOnline per
            offrire ai propri assicurati un servizio innovativo di invio delle
            denunce. <br />
          </Typography>
          <Typography className="color1 text-sectionDas-aziende mt-2">
            {" "}
            Grazie a questa collaborazione, i clienti DAS possono:
            <ul style={{ marginBottom: "0px" }}>
              <li className="mt-1">
                <span>
                  Redigere una{" "}
                  <span style={{ fontWeight: "bold" }}>
                    denuncia direttamente online
                  </span>
                </span>
              </li>
              <li>
                <span>
                  Seguire un{" "}
                  <span style={{ fontWeight: "bold" }}>
                    percorso guidato e sicuro
                  </span>
                </span>
              </li>
              <li>
                <span>
                  Trasmettere la denuncia alla Procura della Repubblica in{" "}
                  <span style={{ fontWeight: "bold" }}>pochi click</span>
                </span>
              </li>
            </ul>
          </Typography>
          <Typography className="color1 text-sectionDas-aziende mt-2">
            Garantiamo insieme un accesso più semplice ed equo alla giustizia,
            con un{" "}
            <span style={{ fontWeight: "bold" }}>
              iter burocratico snello e digitale
            </span>
            , offrendo un supporto concreto agli assicurati.
          </Typography>

          <a
            onClick={openCalendlyPopup}
            style={{ marginTop: "40px" }}
            className="btn btn-header-aziende"
          >
            Richiedi una demo
          </a>
        </div>
      </Grid>

      <Grid
        sx={{ order: { xs: 1, md: 2 } }}
        item
        xs={12}
        md={5}
        className="container-logo-sectionDas-aziende"
      >
        <Grid item xs={12} className="container-grafica-sezioneDas-aziende">
          <div className="grafica-sezioneDas-aziende" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionDas;
