import React from "react";
import { CardContent, Box } from "@mui/material";
import { Card } from "@material-ui/core";
import "../assets/components/text-infobox.scss";
import { AddBoxRounded } from "@mui/icons-material";

export default function TextInfobox({ children, status }) {
  return (
    <Card className={"TextInfobox" + " " + (status || "info")} elevation={0}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignSelf="center"
        padding={1}
        minHeight="105px"
      >
        <Box className="TextInfoboxBar" padding={1} paddingRight={0}></Box>
        <Box
          className="TextInfoboxContent"
          sx={{ paddingTop: 0, paddingBottom: 0, marginLeft: "20px" }}
        >
          {children}
        </Box>
      </Box>
    </Card>
  );
}
