import React from "react";
import "assets/components/footer-one-page.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Instagram, LinkedIn } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

const FooterOnePage = (props) => {
  return (
    <>
      <div className="container-fluid px-0 bg-footer container-section-8">
        <div className={"box-footer"}>
          <div className="logo-footer" style={{ marginBottom: "20px" }}></div>

          <div
            className="col-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              style={{
                color: "#fff",
                fontWeight: 500,
                marginRight: "20px",
                marginTop: "2px",
              }}
            >
              Seguici
            </Typography>
            <a
              href="https://www.instagram.com/denunciaonline.eu"
              target="_blank"
            >
              <Instagram
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                  marginRight: "10px",
                  color: "#fff",
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/denunciaonline/"
              target="_blank"
            >
              <LinkedIn
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                  color: "#fff",
                }}
              />
            </a>
          </div>

          <Grid
            className="container-payment"
            container
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="img-footer-badge"></div>
                <Typography className="text-payment">
                  Pagamenti sicuri con
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="container-logo-payment"
              >
                <div className="img-footer-visa"></div>
                <div className="img-footer-mastercard"></div>
                <div className="img-footer-amex"></div>
                <div className="img-footer-paypal"></div>
                <div className="img-footer-stripe"></div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              color: "#fff",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            <a href="/privacy" className="link-footer" target="_blank">
              Privacy Policy
            </a>{" "}
            -{" "}
            <a href="/cookie-policy" className="link-footer" target="_blank">
              Cookie Policy
            </a>{" "}
            -{" "}
            <a href="/terms-conditions" className="link-footer" target="_blank">
              Termini e condizioni
            </a>
          </Grid>

          <p
            className="mt-3 text-footer"
            style={{ color: "#ffffff", fontSize: "12px", textAlign: "center" }}
          >
            DenunciaOnline è una piattaforma proprietaria di Denuncia Online
            S.r.l., P.IVA e CF 11929180963, Milano. <br /> Tutti i servizi
            offerti dalla piattaforma sono privati e non collegati in alcun modo
            alle forze dell'ordine.
          </p>
          <p
            className="text-info-footer"
            style={{ color: "#ffffff", fontSize: "12px" }}
          >
            &copy; Denuncia Online S.r.l. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterOnePage;
