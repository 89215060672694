import React from "react";
import "assets/components/ForBusinesses/sectionTwo.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Grid } from "@mui/material";

const SectionTwo = ({ openCalendlyPopup }) => {
  return (
    <>
      <div className="container-sectionTwo-aziende">
        <div className="col-8 col-sm-12 col-lg-8 box-sectionTwo-aziende">
          <h2 className="title-sectionTwo-aziende text-light">
            Perché scegliere DenunciaOnline per il tuo business?
          </h2>
          <p
            className="subtitle-sectionTwo-aziende text-light"
            style={{ marginTop: "40px" }}
          >
            DenunciaOnline è la{" "}
            <span style={{ fontWeight: "bold" }}>
              prima piattaforma italiana
            </span>{" "}
            dedicata alla gestione digitale delle denunce, progettata per
            semplificare e automatizzare l’intero processo di raccolta,
            redazione e invio telematico. La tua impresa potrà:
          </p>

          <div className="bubbles-container-aziende">
            {/* A */}
            <div className="d-flex">
              <div
                className="bubble-aziende bubble-text-aziende first-bubble-aziende"
                style={{ width: "80px" }}
              >
                A
              </div>
              <div className="mt-2 box-text-sectionTwo-aziende">
                {/* <p className="text-title-bubble p-0 m-0">Registrati</p> */}
                <p className="subtitle-title-bubble-sectionTwo-aziende mt-1">
                  <span style={{ fontWeight: "bold" }}>
                    Automatizzare la raccolta di informazioni
                  </span>{" "}
                  dai propri clienti o utenti finali, guidandoli in modo chiaro
                  nella compilazione e nell’upload dei file necessari.
                </p>
              </div>
            </div>

            {/* B */}
            <div className="d-flex">
              <div
                className="bubble-aziende bubble-text-aziende second-bubble-aziende"
                style={{ width: "80px" }}
              >
                {/* 02 */}B
              </div>
              <div
                className="box-text-sectionTwo-aziende"
                style={{ marginTop: "-13px" }}
              >
                {/* <p className="text-title-bubble p-0 m-0">Compila la denuncia</p> */}
                <p className="subtitle-title-bubble-sectionTwo-aziende">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Standardizzare i processi di raccolta di documenti
                  </span>{" "}
                  legati a sinistri, frodi o segnalazioni, riducendo errori e
                  tempi di gestione.
                </p>
              </div>
            </div>

            {/* C */}
            <div className="d-flex">
              <div
                className="bubble-aziende bubble-text-aziende last-bubble-aziende three-bubble-aziende"
                style={{ width: "80px" }}
              >
                {/* 03 */}C
              </div>
              <div
                className="box-text-sectionTwo-aziende"
                style={{ marginTop: "0px" }}
              >
                {/* <p className="text-title-bubble p-0 m-0">Ottieni la ricevuta</p> */}
                <p className="subtitle-title-bubble-sectionTwo-aziende">
                  <span style={{ fontWeight: "bold" }}>
                    Garantire conformità normativa
                  </span>{" "}
                  nella gestione e archiviazione della documentazione,
                  assicurando una trasmissione sicura verso le autorità
                  competenti.
                </p>
              </div>
            </div>

            {/* testo  */}
            <div className="d-flex">
              <div style={{ marginTop: "0px", width: "95%" }}>
                <p
                  className="subtitle-sectionTwo-aziende text-light"

                  // className="subtitle-title-bubble"
                >
                  Inoltre, applichiamo la nostra tecnologia alla generazione di{" "}
                  <span style={{ fontWeight: "bold" }}>
                    documenti di qualunque tipo
                  </span>
                  . Siamo il partner perfetto per le aziende che vogliono
                  digitalizzare e rendere più efficienti i loro processi interni
                  di gestione documenti e raccolta di informazioni dalla
                  clientela.
                </p>
              </div>
            </div>

            <Grid container className="col-12 pb-5 box-button">
              {/* <Grid item xs={2} sm={1.4} />
              <Grid
                item
                xs={10}
                sm={5}
                className="link-faq-margin"
                style={{ display: "flex", alignItems: "center" }}
              ></Grid> */}
              <Grid item xs={10} sm={5}>
                <a
                  onClick={openCalendlyPopup}
                  style={{
                    backgroundColor: "#58979d",
                  }}
                  className="btn button-sectionTwo-aziende box-button-SectionTwo-aziende color-purple"
                >
                  Richiedi una demo
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="img-section-3-aziende"></div>
      </div>
    </>
  );
};

export default SectionTwo;
